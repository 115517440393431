import React, { FC, LazyExoticComponent } from 'react';
import { SpacingProps } from '@mui/system';
import { Theme } from '@mui/material';
import { AccountRole } from '../services/types';
import { ThemeProps } from 'styled-components';


export type GlobalStyleProps = {
    theme: ThemeProps<Theme> & { body: any };
};

export interface MuiButtonSpacingType extends SpacingProps {
    component?: React.PropsWithoutRef<{}>;
    to?: string;
}

export interface MuiChipSpacingType extends SpacingProps {
    component?: React.PropsWithoutRef<{}>;
    href?: string;
    icon?: React.ReactElement | null;
}

// Routes
export type RouteInfoType = {
    id?: string;
    name: string;
    path: string;
    icon?: React.ReactElement;
    routes?: Array<RouteInfoType>;
    component: React.ReactNode | LazyExoticComponent<FC<{}>> | null;
    badge?: string | number;
    header?: string;
    hide?: boolean;
    roles?: AccountRole[];
    type?: RouteType;
    redirect?: string;
    index?: boolean;
    module?: RouteModuleType;
    childrenModule?: RouteModuleType[];
};

export enum RouteType {
    section = 'section',
    route = 'route',
}

export enum RouteModuleType {
    invoices = 'invoices',
    other = 'other',
}

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
