import React, { ExoticComponent, lazy } from 'react';
import { Users } from 'react-feather';
import { RouteInfoType } from '../../types/types';
import AuthLayout from '../../layouts/Auth';
import { AccountRole } from '../../services/types';

const SignInPage = lazy(() => import('./pages/signIn')) as ExoticComponent;

const authRoutes = {
    id: 'auth',
    path: '/auth',
    name: 'Авторизация',
    icon: <Users />,
    hide: true,
    roles: [AccountRole.root],
    component: <AuthLayout />,
    routes: [
        {
            path: 'sign-in',
            name: 'Вход',
            component: <SignInPage />,
        },
    ],
} as RouteInfoType;

export default authRoutes;
