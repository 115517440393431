import { makeAutoObservable, runInAction } from 'mobx';
import { execute } from '../apiCommandsExecutor';
import { SignInRequest } from '../apiCommandsExecutor/api';
import { getToken, saveToken, removeToken } from '../apiCommandsExecutor/token';

class AuthStore {
    isAuthorized: boolean;

    constructor() {
        makeAutoObservable(this);
    }

    async checkAuth() {
        const token = getToken();
        if (token === undefined) {
            runInAction(() => {
                this.isAuthorized = false;
            });
        } else {
            runInAction(() => {
                this.isAuthorized = true;
            });
        }
    }

    async logout() {
        removeToken();
        runInAction(() => {
            this.isAuthorized = false;
        });
    }

    async signIn(params: SignInRequest) {
        const response = await execute<string>('users/sign_in', {
            method: 'POST',
            data: {
                ...params,
            },
        });
        if (!response) {
            runInAction(() => {
                this.isAuthorized = false;
            });
            return;
        } else {
            runInAction(() => {
                this.isAuthorized = true;
                saveToken(response);
            });
        }
    }
}

export default AuthStore;
