import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import { execute } from '../apiCommandsExecutor';
import {
    CreateFlatRequest,
    Flat,
    Flats,
    GetFlatsByGroupSetRequest,
    UpdateFlatRequest,
} from '../apiCommandsExecutor/api';
import rootStore from './index';
import { naturalTextComparator } from '../common/sortLibs';

class FlatsStore {
    flats: Flat[] = [];
    currentFlat: Flat;
    currentFlatForMove: Flat;
    currentFlatForRemove: Flat;
    modalState = false;
    moveModal = false;
    removeModal = false;

    constructor() {
        makeAutoObservable(this);
    }

    async getFlats(id: number) {
        const response = await execute<Flats>(
            `groups?skip=${0}&limit=${100}&group_set_id=${id}`,
            {
                method: 'GET',
            },
        );
        if (response) {
            return response.groups;
        } else {
            return null;
        }
    }

    async getFlatsByGroupSet(params: GetFlatsByGroupSetRequest) {
        const response = await execute<Flats>(
            `groups?skip=${params.skip}&limit=${params.limit}&group_set_id=${params.group_set_id}`,
            {
                method: 'GET',
            },
        );
        if (response) {
            runInAction(() => {
                this.flats = response.groups.sort((a, b) => naturalTextComparator(a.sip_id, b.sip_id));
            });
        } else {
            return;
        }
    }

    async createFlat(params: CreateFlatRequest) {
        const response = await execute<Flat>(`groups`, {
            method: 'POST',
            data: {
                ...params,
            },
        });
        if (!response) {
            rootStore.generalStore.showSnackbar(
                'Не удалось добавить новую квартиру',
            );
        } else {
            rootStore.generalStore.showSnackbar(
                `${response.title} успешно добавлена`,
            );
            await this.getFlatsByGroupSet({
                limit: 200,
                skip: 0,
                group_set_id: rootStore.generalStore.currentGroupSetId,
            });
        }
    }

    async updateFlat(params: UpdateFlatRequest) {
        const response = await execute<Flat>(`groups/${this.currentFlat.id}`, {
            method: 'PUT',
            data: {
                ...params,
            },
        });
        if (!response) {
            rootStore.generalStore.showSnackbar('Не удалось обновить квартиру');
        } else {
            rootStore.generalStore.showSnackbar(
                `${response.title} успешно обновлена`,
            );
            await this.getFlatsByGroupSet({
                limit: 200,
                skip: 0,
                group_set_id: rootStore.generalStore.currentGroupSetId,
            });
        }
    }

    async moveFlat(params: UpdateFlatRequest) {
        const response = await execute<Flat>(
            `groups/${this.currentFlatForMove.id}`,
            {
                method: 'PUT',
                data: {
                    ...params,
                },
            },
        );
        if (params.group_set_id === this.currentFlatForMove.group_set_id) {
            rootStore.generalStore.showSnackbar(
                'Эта квартира уже привязана к выбранному объекту',
            );
            return;
        }
        if (!response) {
            rootStore.generalStore.showSnackbar(
                'Не удалось перенести квартиру',
            );
        } else {
            this.closeMoveModal();
            rootStore.generalStore.showSnackbar(
                `${response.title} успешно перенесена`,
            );
            await this.getFlatsByGroupSet({
                limit: 200,
                skip: 0,
                group_set_id: rootStore.generalStore.currentGroupSetId,
            });
        }
    }

    async removeFlat() {
        const response = await execute<boolean>(
            `groups/${this.currentFlatForRemove.id}`,
            {
                method: 'DELETE',
            },
        );
        if (!response) {
            rootStore.generalStore.showSnackbar('Не удалось удалить квартиру');
        } else {
            this.closeRemoveModal();
            rootStore.generalStore.showSnackbar('Квартира удалена');
            await this.getFlatsByGroupSet({
                limit: 200,
                skip: 0,
                group_set_id: rootStore.generalStore.currentGroupSetId,
            });
        }
    }

    @computed
    filterFlatsByGroupSetId(id: number, flats: Flat[]) {
        return flats.filter((i) => i.group_set_id === id);
    }

    @action
    setCurrent(flat: Flat) {
        this.currentFlat = flat;
    }

    @action
    setCurrentForMove(flat: Flat) {
        this.currentFlatForMove = flat;
    }

    @action
    setCurrentForRemove(flat: Flat) {
        this.currentFlatForRemove = flat;
    }

    @action
    openModal(flat?: Flat) {
        flat && this.setCurrent(flat);
        this.modalState = true;
    }

    @action
    closeModal() {
        this.currentFlat = undefined;
        this.modalState = false;
    }

    @action
    openMoveModal(flat: Flat) {
        this.setCurrentForMove(flat);
        this.moveModal = true;
    }

    @action
    closeMoveModal() {
        this.currentFlatForRemove = undefined;
        this.moveModal = false;
    }

    @action
    openRemoveModal(flat: Flat) {
        this.setCurrentForRemove(flat);
        this.removeModal = true;
    }

    @action
    closeRemoveModal() {
        this.currentFlatForRemove = undefined;
        this.removeModal = false;
    }

    @computed
    get flatsById(){
        return this.flats.reduce((memo, item) => {
            memo[item.id]  = item;
            return memo;
        }, {} as  {[s: number]: Flat })
    }
}

export default FlatsStore;
