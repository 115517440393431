import React from 'react';

import { Helmet } from 'react-helmet';


import {
    StyledEngineProvider,
    ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { ThemeProvider } from 'styled-components';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ru } from 'date-fns/locale/ru';

import maTheme from './theme';

function App(router: any) {
    return (
        <React.Fragment>
            <Helmet
                titleTemplate='%s'
                defaultTitle='Wise SIP - домофон'
            />
            <StyledEngineProvider injectFirst>
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ru}
                >
                    <MuiThemeProvider theme={maTheme[0]}>
                        <ThemeProvider theme={maTheme[0]}>
                            {router.children}
                        </ThemeProvider>
                    </MuiThemeProvider>
                </LocalizationProvider>
            </StyledEngineProvider>
        </React.Fragment>
    );
}

export default App;
