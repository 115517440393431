import { makeAutoObservable } from 'mobx';
import GeneralStore from '../common/store';
import AuthStore from './auth';
import GroupSetsStore from './groupSets';
import FlatsStore from './flats';
import SubscribersStore from './subscribers';

const generalStore = new GeneralStore();
const authStore = new AuthStore();
const groupSetsStore = new GroupSetsStore();
const flatsStore = new FlatsStore();
const subscribersStore = new SubscribersStore();

interface IStores {
    generalStore;
    authStore;
    groupSetsStore;
    flatsStore;
    subscribersStore;
}

export class RootStore {
    generalStore: GeneralStore;
    authStore: AuthStore;
    groupSetsStore: GroupSetsStore;
    flatsStore: FlatsStore;
    subscribersStore: SubscribersStore;

    constructor(stores: IStores) {
        makeAutoObservable(this);
        this.generalStore = stores.generalStore;
        this.authStore = stores.authStore;
        this.groupSetsStore = stores.groupSetsStore;
        this.flatsStore = stores.flatsStore;
        this.subscribersStore = stores.subscribersStore;
    }
}

const stores = {
    generalStore,
    authStore,
    groupSetsStore,
    flatsStore,
    subscribersStore,
};

const rootStore = new RootStore(stores);
export default rootStore;
