import { action, makeAutoObservable } from 'mobx';

class GeneralStore {
    currentGroupSetId: number;
    pageTitle = '';
    headerButtons: JSX.Element[];
    snackbar: {
        title: string;
        isOpen: boolean;
    } = {
        title: '',
        isOpen: false,
    };

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setCurrentGroupSetId(id: number) {
        this.currentGroupSetId = id;
    }

    @action
    setPageTitle(title: string, headerButtons: JSX.Element[] = null) {
        this.pageTitle = title;
        this.headerButtons = headerButtons;
    }

    @action
    showSnackbar(title: string) {
        this.snackbar = {
            title: title,
            isOpen: true,
        };
        setTimeout(() => {
            this.closeSnackbar();
        }, 5000);
    }

    @action
    closeSnackbar() {
        this.snackbar = {
            title: '',
            isOpen: false,
        };
    }
}

export default GeneralStore;
