import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../hooks';
import { Outlet, useNavigate } from 'react-router-dom';

const SecurityLayout: React.FC = () => {
    const [isReady, setIsReady] = useState(false);
    const { authStore } = useStores();
    const navigate = useNavigate();

    useEffect(() => {
        authStore.checkAuth().then(() => setIsReady(true));
    }, []);

    useEffect(() => {
        if (!isReady) return;
        const { isAuthorized } = authStore;

        if (isAuthorized && window.location.pathname === '/') {
            navigate('/wise-sip/flats');
        }

        if (!isAuthorized) {
            navigate('/auth/sign-in');
        }
    }, [isReady, authStore.isAuthorized]);

    return <Outlet />;
};

export default observer(SecurityLayout);
