import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getToken } from './token';
import { GeneralResponse, ResponseStatus } from './api';

export const API_URL = '';

export const execute = async <ResponseType>(
    url: string,
    config?: AxiosRequestConfig,
) => {
    const result: AxiosResponse<GeneralResponse<ResponseType>> =
        await axios.request({
            ...config,
            url: url,
            baseURL: `${API_URL}/api/`,
            headers: {
                Authorization: `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
            },
            validateStatus: () => true,
        });

    if (result.data && result.data.status === ResponseStatus.finished) {
        return result.data.result;
    }

    return null;
};
