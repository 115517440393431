import * as React from 'react';
import { ComponentType } from 'react';
import styled, { withTheme } from 'styled-components';

import {
    Grid,
    Hidden,
    IconButton as MuiIconButton,
    Toolbar,
    Typography,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useStores } from '../../hooks';
import { observer } from 'mobx-react';

const AppBar = styled.div`
    padding-top: 40px;
    background: ${(props) => props.theme.header.background};
    color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
    svg {
        width: 22px;
        height: 22px;
    }
`;

type HeaderProps = {
    theme: {};
};

type OwnProps = {
    onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Header: React.FC<HeaderProps & OwnProps> = ({ onDrawerToggle }) => {
    const { generalStore } = useStores();
    return (
        <React.Fragment>
            <AppBar>
                <Toolbar>
                    <Grid container alignItems='center'>
                        <Hidden mdUp>
                            <Grid item>
                                <IconButton
                                    color='inherit'
                                    aria-label='Open drawer'
                                    onClick={onDrawerToggle}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Grid>
                        </Hidden>
                        <Grid item>
                            <div style={{ color: '#000', marginLeft: 20 }}>
                                <Typography
                                    variant='h3'
                                    gutterBottom
                                    display='inline'
                                >
                                    {generalStore.pageTitle}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs />
                        {generalStore.headerButtons?.map((btn) => (
                            <div
                                key={btn.key || Date.now().toString()}
                                style={{
                                    display: 'inline-block',
                                    marginLeft: 10,
                                }}
                            >
                                <Grid item>{btn}</Grid>
                            </div>
                        ))}
                    </Grid>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
};

export default withTheme(observer(Header)) as ComponentType<OwnProps>;
