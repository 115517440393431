import React from 'react';
import logo from '../../../assets/wiseLogo.png';
import { Button } from '@mui/material';

const ErrorScreen = () => {
    return (
        <>
            <div
                style={{
                    background:
                        'linear-gradient(176.43deg, #1F4377 9.7%, #0E3369 95.29%)',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <img
                        src={logo}
                        alt=''
                        style={{
                            height: 28,
                            margin: '8px 0 7px 15px',
                        }}
                    />
                    <div
                        style={{
                            width: 28,
                            height: 0,
                            border: '1px solid #FFFFFF',
                            transform: 'rotate(90deg)',
                        }}
                    ></div>
                    <span
                        style={{
                            fontFamily: 'Formular',
                            fontWeight: 400,
                            fontSize: 16,
                            color: '#fff',
                            margin: '8px 0 7px 0',
                        }}
                    >
                        Ошибка
                    </span>
                </div>
            </div>
            <div>
                <h2
                    style={{
                        display: 'block',
                        fontSize: '1.5rem',
                        marginBlockStart: '0.83rem',
                        marginBlockEnd: '0.83rem',
                        marginInlineStart: 0,
                        marginInlineEnd: 0,
                        fontWeight: 'bold',
                    }}
                >
                    Произошла непредвиденная ошибка.
                </h2>
                <h3
                    style={{
                        fontStyle: 'italic',
                        fontSize: '1.17rem',
                        marginBlockStart: '1rem',
                        marginBlockEnd: '1rem',
                        marginInlineStart: 0,
                        marginInlineEnd: 0,
                        fontWeight: 'bold',
                    }}
                >
                    Попробуйте обновить страницу или вернуться на предыдущую.
                </h3>
                <h3
                    style={{
                        fontStyle: 'italic',
                        fontSize: '1.17rem',
                        marginBlockStart: '1rem',
                        marginBlockEnd: '1rem',
                        marginInlineStart: 0,
                        marginInlineEnd: 0,
                        fontWeight: 'bold',
                    }}
                >
                    Наша команда была уведомлена о проблеме и уже занимается
                    поиском решений!
                </h3>
                <Button
                    variant={'contained'}
                    style={{ backgroundColor: '#1F4377', color: '#ffffff' }}
                    onClick={() => window.location.reload()}
                >
                    Обновить
                </Button>
            </div>
        </>
    );
};

export default ErrorScreen;
