import { action, makeAutoObservable, runInAction } from 'mobx';
import { execute } from '../apiCommandsExecutor';
import {
    CreateGroupSet,
    GetGroupSetsRequest,
    GroupSet,
    GroupSets,
    UpdateGroupSet,
} from '../apiCommandsExecutor/api';
import rootStore from './index';

class GroupSetsStore {
    groupSets: GroupSet[] = [];
    currentSet: GroupSet;
    currentSetForRemove: GroupSet;
    modalState = false;
    removeModal = false;

    constructor() {
        makeAutoObservable(this);
    }

    async getGroupSets(params: GetGroupSetsRequest) {
        const response = await execute<GroupSets>(
            `group_sets?skip=${params.skip}&limit=${params.limit}`,
            {
                method: 'GET',
            },
        );
        if (response) {
            runInAction(() => {
                this.groupSets = response.group_sets;
            });
        } else {
            rootStore.authStore.isAuthorized = false;
        }
    }

    async createGroupSet(params: CreateGroupSet) {
        const response = await execute<GroupSet>(`group_sets`, {
            method: 'POST',
            data: {
                ...params,
            },
        });
        if (!response) {
            rootStore.generalStore.showSnackbar(
                'Не удалось добавить новый объект',
            );
        } else {
            rootStore.generalStore.showSnackbar(
                `${response.title} успешно добавлен`,
            );
            await this.getGroupSets({
                limit: 200,
                skip: 0,
            });
        }
    }

    async updateGroupSet(params: UpdateGroupSet) {
        const response = await execute<GroupSet>(
            `group_sets/${this.currentSet.id}`,
            {
                method: 'PUT',
                data: {
                    ...params,
                },
            },
        );
        if (!response) {
            rootStore.generalStore.showSnackbar('Не удалось обновить объект');
        } else {
            rootStore.generalStore.showSnackbar(
                `${response.title} успешно обновлён`,
            );
            await this.getGroupSets({
                limit: 200,
                skip: 0,
            });
        }
    }

    async removeGroupSet() {
        const response = await execute<boolean>(
            `group_sets/${this.currentSetForRemove.id}`,
            {
                method: 'DELETE',
            },
        );
        if (!response) {
            rootStore.generalStore.showSnackbar('Не удалось удалить объект');
        } else {
            this.closeRemoveModal();
            rootStore.generalStore.showSnackbar('Объект удалён из системы');
            await this.getGroupSets({
                limit: 200,
                skip: 0,
            });
        }
    }

    @action
    setCurrent(set: GroupSet) {
        this.currentSet = set;
    }

    @action
    setCurrentForRemove(set: GroupSet) {
        this.currentSetForRemove = set;
    }

    @action
    openModal(set?: GroupSet) {
        set && this.setCurrent(set);
        this.modalState = true;
    }

    @action
    closeModal() {
        this.currentSet = undefined;
        this.modalState = false;
    }

    @action
    openRemoveModal(set: GroupSet) {
        this.setCurrentForRemove(set);
        this.removeModal = true;
    }

    @action
    closeRemoveModal() {
        this.currentSetForRemove = undefined;
        this.removeModal = false;
    }
}

export default GroupSetsStore;
