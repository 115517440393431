export const getToken = () => {
    const token = localStorage.getItem('sip-server-token');

    if (token) {
        return JSON.parse(token);
    } else {
        return '';
    }
};

export const saveToken = (token) => {
    if (!token) return;
    localStorage.setItem('sip-server-token', JSON.stringify(token));
};

export const removeToken = () => {
    localStorage.removeItem('sip-server-token');
};
