import React from 'react';
import authRoutes from '../modules/auth/routes';
import maintainerRoutes from '../modules/maintainer/routes';
import App from '../App';

import SecurityLayout from '../layouts/SecurityLayout';
import rootStore from '../stores';
import { StoreProvider } from '../contexts';
import { RouteInfoType, RouteType } from '../types/types';
import {
    createBrowserRouter,
    Navigate,
    NonIndexRouteObject,
    RouteObject,
    RouterProvider,
} from 'react-router-dom';
import { IndexRouteObject } from 'react-router/dist/lib/context';
import ErrorBoundary from '../common/components/ErrorBoundary/ErrorBoundary';

export const config = [
    authRoutes,
    {
        id: 'common',
        path: '/',
        name: 'Общие',
        hide: false,
        component: <SecurityLayout />,
        routes: [maintainerRoutes],
    },
];

const authorizedRoutes = [maintainerRoutes];

const convertRoutesNew = () => {
    return [
        renderRouteInfoType(authRoutes),
        {
            path: '/',
            element: <SecurityLayout />,
            children: authorizedRoutes.map(renderRouteInfoType),
        },
    ];
};

const renderRouteInfoType = (routeItem: RouteInfoType): RouteObject => {
    if (routeItem.routes && routeItem.routes.length > 0) {
        const renderedRoutes = routeItem.routes
            .filter((t) => t.type !== RouteType.section)
            .map(renderRouteInfoType);
        return {
            path: routeItem.path,
            element: <ErrorBoundary>{routeItem.component}</ErrorBoundary>,
            children: renderedRoutes,
        };
    } else {
        if (routeItem.redirect) {
            return {
                path: routeItem.path,
                element: <Navigate replace to={routeItem.redirect} />,
                index: routeItem.index,
            } as NonIndexRouteObject | IndexRouteObject;
        }

        return {
            path: routeItem.path,
            element: routeItem.component,
            index: routeItem.index,
        } as NonIndexRouteObject | IndexRouteObject;
    }
};

function RouterConfig() {
    return (
        <App>
            <StoreProvider store={rootStore}>
                <RouterProvider
                    router={createBrowserRouter(convertRoutesNew())}
                />
            </StoreProvider>
        </App>
    );
}

export default RouterConfig;
