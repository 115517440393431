// Сортирует строки в алфавитном порядке
// если встречаются числа, то учитывая числа
// то есть  "Парадная 10", "Парадная 1",и "Парадная 2" при обычном сравнении строк будут отсортированы
// "Парадная 1", "Парадная 10", "Парадная 2"
// а при текущей сортировке будут отсортированы
// "Парадная 1", "Парадная 2", "Парадная 10"
export const naturalTextComparator = (a: string, b: string) => {
    const ax = [],
        bx = [];

    //@ts-ignore
    a.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
        ax.push([$1 || Infinity, $2 || '']);
    });
    //@ts-ignore
    b.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
        bx.push([$1 || Infinity, $2 || '']);
    });

    while (ax.length && bx.length) {
        const an = ax.shift();
        const bn = bx.shift();
        const nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
        if (nn) return nn;
    }

    return ax.length - bx.length;
};
